<template>
  <div style="text-align: justify">
    <p class="mb-6">
      The heat of neutralization is the heat of reaction when one mole of acid reacts with one mole
      of base to create one mole of salt and one mole of water. Use the following data to determine
      the heat of neutralization (in kJ/mol) of
      <chemical-latex content="HCl" /> by
      <chemical-latex content="NaOH." />
    </p>

    <p class="mb-6">
      For this problem, you may assume that the densities of the solutions of
      <chemical-latex content="HCl" /> and <chemical-latex content="NaOH" /> are
      <latex-number number="1.000" unit="\text{g/mL.}" />
    </p>

    <p class="mb-1">
      <u>Data:</u>
    </p>

    <v-simple-table style="margin-left: 15px; margin-bottom: 20px">
      <tbody>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Mass of}$" />
            <number-value :value="acidBaseConc" unit="\text{M}" />
            <stemble-latex content="$\text{HCl used}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,100.0\text{ g}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Mass of}$" />
            <number-value :value="acidBaseConc" unit="\text{M}" />
            <stemble-latex content="$\text{NaOH used}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,100.0\text{ g}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Temperature of solutions before mixing}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <latex-number :number="Ti" />
            <stemble-latex content="$^{\circ}\text{C}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Temperature of solutions after mixing}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="Tf" unit="^{\circ}\text{C}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Specific heat of}$" />
            <latex-number :number="saltConc" />
            <stemble-latex content="$\text{M aq. NaCl}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,3.955\text{ J g}^{-1}\,^{\circ}\text{C}^{-1}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Heat capacity of the calorimeter}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="Ccal" unit="\text{J/}^{\circ}\text{C}" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'UPEICalorimetryPreLabPt1',
  components: {
    ChemicalLatex,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    acidBaseConc() {
      return this.taskState.getValueBySymbol('acidBaseConc').content;
    },
    saltConc() {
      return (this.acidBaseConc.toFloat() / 2).toFixed(3);
    },
    Ccal() {
      return this.taskState.getValueBySymbol('Ccal').content;
    },
    Tf() {
      return this.taskState.getValueBySymbol('Tf').content;
    },
    qNeut() {
      return this.taskState.getValueBySymbol('qNeut').content;
    }, // This value is scaled by a factor of 8
    Ti() {
      return (
        ((this.qNeut.toFloat() / 8) * 100 * this.acidBaseConc.toFloat()) /
          (3.955 * 200.0 + this.Ccal.toFloat()) +
        this.Tf.toFloat()
      ).toFixed(2);
    },
  },
};
</script>
